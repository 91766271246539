/**
 * @description: 处理画布数据
 * @param {any} canvasJson xcs文件画布json数据
 * @return {any} 去重后的全部加工数据
 */
export const processDisplayData = (canvasJson: any) => {
  const { device } = canvasJson;
  if (!device || !device.data) {
    return;
  }
  const allDisplay = [] as any[];
  try {
    const deviceMap = device.data;
    const deviceId = device.id;
    const power = device.power;
    // 遍历画布
    deviceMap.forEach((val: any, canvasId: string) => {
      const currentCanvas = val;
      const mode = currentCanvas.mode;
      const commonParams = currentCanvas.data[mode]; // 当前画布的公共配置
      const material = commonParams.material;
      let materialOfficial = null as any;
      // material == 0 表示无耗材
      if (material > 0) {
        // 有材料的画布才处理
        materialOfficial = device.materialList
          .filter((item: any) => item.id === material)
          .map((val: any) => {
            const imgUrl = val.image || val.designImage || (val.images && val.images[0]);
            return {
              id: val.id,
              name: val.name,
              materialType: 'official',
              imgUrl,
            };
          })[0]; // 当前画布用的材料
      }

      const displays = currentCanvas.displays; // 当前画布的所有display
      displays.forEach((currentDisplay: any, displayId: string) => {
        const processingType = currentDisplay.processingType;
        const data = currentDisplay.data[processingType];
        const materialType = data?.materialType;
        // parameter 根据 materialType来的， 如果materialType === official 就取 official
        const key = materialType !== 'official' ? 'customize' : 'official';
        const parameter = data?.parameter[key];
        const { processingLightSource, ...others } = parameter;
        Object.keys(others)
          .filter((key) => {
            return typeof others[key] === 'object';
          })
          .forEach((key) => {
            delete others[key];
          });
        const params = {
          existCanvas: [
            {
              canvasId,
              displayIds: [displayId],
            },
          ],
          deviceId,
          power,
          mode,
          materialOfficial,
          processingType,
          materialType: key, // 标注是官方参数还是自定义参数
          parameter: others,
          laserType: processingLightSource, // 红蓝光
        };
        allDisplay.push(params);
      });
    });
  } catch (e) {
    console.error(['exportAllDisplay error', e]);
  }
  const res = uniqWithDisplay(allDisplay) as any;
  return res;
};
// 加工参数去重，需要将canvasId和displayId记录整合
const uniqWithDisplay = (allDisplay: any[]) => {
  if (allDisplay.length === 0) {
    return [];
  }
  try {
    const uniqArr = [] as any[];
    allDisplay.forEach((display: any) => {
      const index = uniqArr.findIndex((val) => {
        const displayCopy = JSON.parse(JSON.stringify(display));
        const valCopy = JSON.parse(JSON.stringify(val));
        delete displayCopy.existCanvas; // 删除不需要比对的字段
        delete valCopy.existCanvas;
        delete displayCopy.materialType;
        delete valCopy.materialType;
        return JSON.stringify(displayCopy) === JSON.stringify(valCopy);
      });

      if (index === -1) {
        uniqArr.push(display);
      } else {
        const current = uniqArr[index].existCanvas;
        const canvasIdx = current.findIndex((val: any) => val.canvasId === display.existCanvas[0].canvasId);
        if (canvasIdx > -1) {
          current[canvasIdx].displayIds.push(display.existCanvas[0].displayIds[0]);
        } else {
          current.push(...display.existCanvas);
        }
      }
    });
    return uniqArr;
  } catch (e) {
    console.error(['uniqWithDisplay error', e]);
    return [];
  }
};
